import { Box, Stack, Typography } from "@mui/material";

export function IntroductionPage(): JSX.Element {
  return (
    <Stack maxWidth="800px" justifyContent="center" marginX="auto">
      <Typography variant="h5">Introduction: Rabbit Kingdom (temp)</Typography>
      <Box sx={{ overflowY: "auto" }}>
        <Typography>To be available soon</Typography>
      </Box>
    </Stack>
  );
}
