export enum CardType {
  Building = "Building",
  Skill = "Skill",
}

export abstract class AbstractCard {
  abstract cardType: CardType;
  abstract cardName: string;
  abstract foodCost: number;
  abstract materialCost: number;
  abstract cardInfo: string[];

  handleCost(): void {}
  abstract operateCard(): void;
}
