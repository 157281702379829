import { Building, BuildingPhase } from "../buildings/Building";
import { base_hp_default } from "../../config/BaseValue";
import { GameController } from "../GameController";

export class Base extends Building {
  type: string = "Base";
  name: string = "base";
  hp: number = base_hp_default;
  maxHp: number = base_hp_default;
  parse: BuildingPhase = BuildingPhase.Ready;
  allocatedRabbits: number = 0;
  nextRound(): void {}
  destroyed(): void {
    GameController.getController().endgame(false);
  }

  getDamaged(damage: number): void {
    this.hp -= damage;
    if (this.hp <= 0) {
      this.destroyed();
    }
  }
}
