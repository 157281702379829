import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Stack,
  Grid,
} from "@mui/material";
import { Component } from "react";
import { ResourcePoint } from "../../resources/ResourcePoint";

export interface ResourcePointUIProp {
  model: ResourcePoint;
}

interface ResourcePointUIState {
  average: number;
  diversity: number;
  collectNum: number;
  ambushNum: number;
  protectNum: number;
}

export class ResourcePointUI extends Component<
  ResourcePointUIProp,
  ResourcePointUIState
> {
  model: ResourcePoint;
  constructor(props: ResourcePointUIProp) {
    super(props);
    this.model = props.model;
    this.model.bindUI(this);
    this.state = {
      average: this.model.expectation,
      diversity: this.model.diversity,
      collectNum: this.model.collectNum,
      ambushNum: this.model.ambushNum,
      protectNum: this.model.protectNum,
    };
  }

  updateUI() {
    this.setState({
      average: this.model.expectation,
      diversity: this.model.diversity,
      collectNum: this.model.collectNum,
      ambushNum: this.model.ambushNum,
      protectNum: this.model.protectNum,
    });
  }

  render(): JSX.Element {
    return (
      <Box margin="5px">
        <Card>
          <CardContent>
            <Stack direction="column" alignItems="flex-start">
              <Typography color="gray">{this.model.type} Resource</Typography>
              <Typography variant="h4">{this.model.name}</Typography>
              <Typography>
                Average: {this.state.average.toFixed(3)} (±
                {this.state.diversity * 100}%)
              </Typography>
            </Stack>
          </CardContent>
          <CardActions>
            <Grid container width="250px">
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => this.model.addForecast()}
                >
                  Forecast
                </Button>
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2} xl={2} />
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Stack direction="column" spacing={1}>
                  <Button
                    variant="outlined"
                    onClick={() => this.model.addAmbush()}
                  >
                    Ambush {this.state.ambushNum}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => this.model.addProtect()}
                  >
                    Protect {this.state.protectNum}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => this.model.addCollect()}
                  >
                    Collect {this.state.collectNum}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Box>
    );
  }
}
