import { HomeController } from "../../HomeController";
import { BuildingRouter } from "../buildings/BuildingRouter";
import { Stack } from "@mui/material";
import { Component } from "react";
import { Building } from "../../buildings/Building";

interface BuildingsUIPartProps {}

interface BuildingsUIPartState {
  buildings: Building[];
}
export class BuildingsUIPart extends Component<
  BuildingsUIPartProps,
  BuildingsUIPartState
> {
  constructor(props: BuildingsUIPartProps) {
    super(props);
    HomeController.getInstance().buildingsUI = this;
    this.state = {
      buildings: HomeController.getInstance().buildings,
    };
  }

  updateUI() {
    this.setState({
      buildings: HomeController.getInstance().buildings,
    });
  }
  render(): JSX.Element {
    return (
      <Stack direction="row" alignItems="flex-start" flexWrap="wrap" gap={1}>
        {this.state.buildings.map((building, index) =>
          BuildingRouter(building, index)
        )}
      </Stack>
    );
  }
}
