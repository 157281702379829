import { EnemyBase } from "./enemies/EnemyBase";
import { EnemyBuilding } from "./enemies/EnemyBuilding";
import { EnemyBreedingBooster } from "./enemies/EnemyBreedingBooster";

export class EnemyController {
  static controller: EnemyController | null = null;
  static getController(): EnemyController {
    if (EnemyController.controller === null)
      EnemyController.controller = new EnemyController();
    return EnemyController.controller;
  }

  base: EnemyBase;
  buildings: EnemyBuilding[] = [];
  updateEnemyUI: (() => void) | undefined = undefined;

  constructor() {
    this.base = new EnemyBase();
    this.buildings.push(new EnemyBreedingBooster());
  }
}
