import { ResourcePoint } from "./resources/ResourcePoint";
import { ResourcesUIPart } from "./ui/parts/ResourcesUIPart";
import { ValueUtils } from "../utils/ValueUtils";

export class ResourceController {
  private static controller: ResourceController | null = null;
  static getController(): ResourceController {
    if (ResourceController.controller === null)
      ResourceController.controller = new ResourceController();
    return ResourceController.controller;
  }

  ui: ResourcesUIPart | undefined;
  updateResourcesUI(): void {
    if (this.ui === undefined) return;
    this.ui.updateUI();
  }

  resources: ResourcePoint[] = [
    this.generateRandomResourcePointWithFood(),
    this.generateRandomResourcePointWithFood(),
    this.generateRandomResourcePointWithFood(),
    this.generateRandomResourcePoint(),
  ];

  nextRound(): void {
    this.resources.forEach((resource) => resource.endTurn());
    this.resources = [
      this.generateRandomResourcePoint(),
      this.generateRandomResourcePoint(),
      this.generateRandomResourcePoint(),
    ];
    this.updateResourcesUI();
  }

  generateRandomResourcePoint(): ResourcePoint {
    const index: number = Math.floor(
      Math.random() * ResourcesTemplateList.length
    );
    const template: ResourcesTemplate = ResourcesTemplateList[index];
    const expectation: number = ValueUtils.random(
      template.expectationStart,
      template.expectationEnd
    );
    return new ResourcePoint(
      template.type,
      template.name,
      expectation,
      undefined,
      undefined,
      undefined
    );
  }

  generateRandomResourcePointWithFood(): ResourcePoint {
    const rp = this.generateRandomResourcePoint();
    rp.type = "Food";
    return rp;
  }
}

interface ResourcesTemplate {
  name: string;
  type: "Food" | "Material";
  expectationStart: number;
  expectationEnd: number;
}

const ResourcesTemplateList: ResourcesTemplate[] = [
  {
    name: "City",
    type: "Material",
    expectationStart: 2,
    expectationEnd: 8,
  },
  {
    name: "Farm",
    type: "Food",
    expectationStart: 1,
    expectationEnd: 5,
  },
  {
    name: "Supermarket",
    type: "Food",
    expectationStart: 3,
    expectationEnd: 4,
  },
];
