import { Building, BuildingPhase } from "./Building";
import { CarrotCannonUI } from "../ui/buildings/CarrotCannonUI";
import { HomeController } from "../HomeController";
import { GameController } from "../GameController";
import { EnemyBuilding } from "../enemies/EnemyBuilding";

export class CarrotCannon extends Building {
  static carrotCannonCount: number = 0;

  type: string = "CarrotCannon";
  ui: CarrotCannonUI | undefined;
  bindUI(ui: CarrotCannonUI): void {
    this.ui = ui;
  }

  constructor() {
    super();
    this.name = "Carrot Cannon " + CarrotCannon.carrotCannonCount.toString();
    CarrotCannon.carrotCannonCount += 1;
  }

  name: string = "Carrot Cannon";
  allocatedRabbits: number = 0;
  hp: number = 100;
  maxHp: number = 100;
  remainingConstruction: number = 10;
  maxConstruction: number = 10;
  attack: number = 10;

  parse: BuildingPhase = BuildingPhase.Building;

  destroyed(): void {}
  nextRound(): void {
    const allocated: number = this.allocatedRabbits;
    this.allocatedRabbits = 0;
    if (this.parse === BuildingPhase.Building) {
      // Under Construction
      this.buildingCount -= allocated;
      if (allocated > 0) {
        GameController.getController().addLog(
          `Allocated ${allocated} rabbit to build ${this.name}`
        );
      }
      if (this.buildingCount <= 0) {
        this.parse = BuildingPhase.Ready;
        GameController.getController().numUnderConstruction -= 1;
        GameController.getController().addLog(
          `Construction completed: ${this.name}`
        );
      }
    } else {
      // Attack
      if (this.target) {
        this.target.getDamaged(this.attack);
        GameController.getController().addLog(
          `${this.name} give 10 points of damage to ${this.target.name}`
        );
        this.target = undefined;
      }
    }
    this.ui?.updateUI();
  }
  getDamaged(damage: number): void {
    this.hp -= damage;
    if (this.hp <= 0) this.destroyed();
  }

  addRabbit: () => void = () => {
    if (!HomeController.getInstance().allocateRabbit()) return;
    this.allocatedRabbits += 1;
    this.ui?.updateUI();
  };

  target: EnemyBuilding | undefined;
  getTargetName(): string | undefined {
    if (this.target === undefined) return undefined;
    return this.target.name;
  }
  startSelectTarget: () => void = () => {
    GameController.getController().handleTargetSelect = (
      input: EnemyBuilding
    ) => {
      this.target = input;
      this.ui?.updateUI();
    };
  };
  buildingCount: number = 10;
}
