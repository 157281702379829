import { EnemyBuilding } from "./EnemyBuilding";
import { EnemyBuildingUI } from "../ui/enemies/EnemyBuildingUI";

export class EnemyBreedingBooster extends EnemyBuilding {
  type: string = "Enemy_Breeding_Booster";
  name: string = "Breeding Booster";
  hp: number = 20;
  maxHp: number = 20;
  ui: EnemyBuildingUI | undefined;
  updateUI() {
    if (this.ui === undefined) return;
    this.ui.updateUI();
  }

  bindUi(ui: EnemyBuildingUI) {
    this.ui = ui;
  }
  getDamaged(damage: number) {
    super.getDamaged(damage);
    this.ui?.updateUI();
  }
}
