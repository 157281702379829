import { Component } from "react";
import { EnemyBuilding } from "../../enemies/EnemyBuilding";
import { Paper, Stack, Typography } from "@mui/material";
import { GameController } from "../../GameController";

export interface EnemyBuildingUIProp {
  model: EnemyBuilding;
}

interface EnemyBuildingUIState {
  hp: number;
}

export class EnemyBuildingUI extends Component<
  EnemyBuildingUIProp,
  EnemyBuildingUIState
> {
  model: EnemyBuilding;
  constructor(props: EnemyBuildingUIProp) {
    super(props);
    this.model = props.model;
    this.model.bindUi(this);
    this.state = {
      hp: this.model.hp,
    };
  }

  handleSelection: () => void = () => {
    const handleFunction = GameController.getController().handleTargetSelect;
    if (handleFunction === undefined) return;
    handleFunction(this.model);
    GameController.getController().handleTargetSelect = undefined;
  };

  updateUI() {
    this.setState({
      hp: this.model.hp,
    });
  }

  render(): JSX.Element {
    return (
      <Paper elevation={3} onClick={this.handleSelection}>
        <Stack
          direction="column"
          alignItems="flex-start"
          padding="10px"
          gap="5px"
        >
          <Typography>{this.model.name}</Typography>
          <Typography>
            {this.state.hp} / {this.model.maxHp}
          </Typography>
        </Stack>
      </Paper>
    );
  }
}
