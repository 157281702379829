import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Stack,
  Typography,
} from "@mui/material";
import { EnemyUIPart } from "./parts/EnemyUIPart";
import { StackInfoUIPart } from "./parts/StackInfoUIPart";
import { BaseUIPart } from "./parts/BaseUIPart";
import { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { GameController } from "../GameController";
import { CardUI } from "./cards/CardUI";
import { BuildingsUIPart } from "./parts/BuildingsUIPart";
import { ResourcesUIPart } from "./parts/ResourcesUIPart";
import { LogUIPart } from "./parts/LogUIPart";

enum ControlPanel {
  mainPanel,
  buildingPanel,
  infoPanel,
}
export function GameCanvas(): JSX.Element {
  const [panel, setPanel] = useState<ControlPanel>(ControlPanel.mainPanel);
  const [endGameShow, setEndGameShow] = useState(false);

  useEffect(() => {
    GameController.getController().showEndGameTrigger = () => {
      setEndGameShow(true);
    };
  }, []);

  function MainPanel(): JSX.Element {
    return (
      <Stack direction="row">
        <Box paddingX="10px" width="15vw" alignSelf="stretch">
          <BaseUIPart />
        </Box>
        <Box width="45vw">
          <BuildingsUIPart />
        </Box>
      </Stack>
    );
  }
  function ConstructionPanel(): JSX.Element {
    return (
      <Box marginLeft="10px">
        <Stack direction="row" gap="5px">
          {GameController.getController().cards.map((card, index) => (
            <CardUI model={card} key={index}></CardUI>
          ))}
        </Stack>
      </Box>
    );
  }
  function ShowPanel(selectPanel: ControlPanel): JSX.Element {
    if (selectPanel === ControlPanel.mainPanel) return <MainPanel />;
    if (selectPanel === ControlPanel.buildingPanel)
      return <ConstructionPanel />;
    return <div></div>;
  }

  return (
    <Box height="100vh">
      <Stack direction="column" height="100%">
        <Stack direction="row" flexGrow={1}>
          <Stack direction="column" flexGrow={1}>
            <Box
              height="100px"
              alignSelf="stretch"
              paddingY="10px"
              sx={{ borderBottom: "1px dashed black" }}
            >
              <EnemyUIPart />
            </Box>
            <Box flexGrow={1}>
              <ResourcesUIPart />
            </Box>
          </Stack>
          <Box border="1px solid black" width="350px">
            <LogUIPart />
          </Box>
        </Stack>
        <Box
          height="240px"
          padding="10px"
          marginTop="auto"
          sx={{ borderTop: "1px dashed black" }}
        >
          <Stack direction="row" alignContent="flex-start">
            <Box width="15vw">
              <StackInfoUIPart />
            </Box>
            <Stack
              direction="column"
              width="5vw"
              alignSelf="stretch"
              alignItems="flex-end"
            >
              <HomeIcon
                onClick={() => setPanel(ControlPanel.mainPanel)}
                cursor="pointer"
              />
              <CropPortraitIcon
                onClick={() => setPanel(ControlPanel.buildingPanel)}
                cursor="pointer"
              />
              <CropFreeIcon
                onClick={() => setPanel(ControlPanel.infoPanel)}
                cursor="pointer"
              />
            </Stack>
            <Box width="60vw">{ShowPanel(panel)}</Box>
            <Stack direction="column" gap="10px">
              <Button
                variant="outlined"
                color="error"
                onClick={() => GameController.getController().nextRound()}
              >
                End turn
              </Button>
              <Button variant="outlined">Reset</Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Dialog open={endGameShow} onClose={() => setEndGameShow(false)}>
        <Card>
          <CardContent>
            <Typography>
              `You $
              {GameController.getController().gameResult.win ? "Win" : "Lose"}{" "}
              the game`
            </Typography>
            <CardActions>
              <Button>Restart</Button>
            </CardActions>
          </CardContent>
        </Card>
      </Dialog>
    </Box>
  );
}
