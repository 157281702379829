import { EnemyBuilding } from "./enemies/EnemyBuilding";
import { AbstractCard } from "./cards/AbstractCard";
import { CarrotCannonCard } from "./cards/CarrotCannonCard";
import { HomeController } from "./HomeController";
import { ResourceController } from "./ResourceController";

interface GameResult {
  win: boolean;
}
export class GameController {
  private static controller: GameController | null;
  static getController(): GameController {
    if (GameController.controller == null)
      GameController.controller = new GameController();
    return GameController.controller;
  }

  turn: number = 1;
  gameLog: string[] = ["to be implemented"];
  addLog(log: string) {
    this.gameLog.push(log);
  }
  andLogAndRender(log: string) {
    this.addLog(log);
    if (this.updateLogUI) this.updateLogUI();
  }
  updateLogUI: (() => void) | undefined;

  gameResult: GameResult = {
    win: false,
  };

  showEndGameTrigger: () => void = () => {};

  handleTargetSelect: ((target: EnemyBuilding) => void) | undefined = undefined;

  cards: AbstractCard[] = [new CarrotCannonCard()];

  numUnderConstruction: number = 0;

  nextRound(): void {
    this.addLog(`Round ${this.turn}`);
    ResourceController.getController().nextRound();
    HomeController.getInstance().buildings.forEach((building) =>
      building.nextRound()
    );
    HomeController.getInstance().nextRound();
    this.turn += 1;
    if (this.updateLogUI) this.updateLogUI();
  }

  endgame(win: boolean): void {
    this.gameResult.win = win;
    this.showEndGameTrigger();
    console.log(`You ${win ? "Win" : "Lose"} the game`);
  }
}
