import React from "react";
import "./App.css";
import { GameCanvas } from "./game/ui/GameCanvas";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IntroductionPage } from "./pages/IntroductionPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GameCanvas />} />
        <Route path="/intro" element={<IntroductionPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
