import { EnemyBuildingUI } from "../ui/enemies/EnemyBuildingUI";
import { EnemyController } from "../EnemyController";

export abstract class EnemyBuilding {
  abstract type: string;
  abstract name: string;
  abstract hp: number;
  abstract maxHp: number;
  abstract ui: EnemyBuildingUI | undefined;
  abstract bindUi(ui: EnemyBuildingUI): void;
  abstract updateUI(): void;
  getDamaged(damage: number): void {
    this.hp -= damage;
    this.updateUI();
    if (this.hp <= 0) this.destroyed();
  }
  destroyed(): void {
    EnemyController.getController().buildings =
      EnemyController.getController().buildings.filter(
        (building) => building.name !== this.name
      );
    const updateUI: (() => void) | undefined =
      EnemyController.getController().updateEnemyUI;
    if (updateUI !== undefined) updateUI();
  }
}
