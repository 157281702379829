import {init_rabbit_number} from "../../config/BaseValue";

export class Rabbits {
    total: number = init_rabbit_number;
    injured: number = 0;
    poisoned: number = 0;
    breeding: number = 0;
    occupied: number = 0;

    getAvailable(): number {
        return this.total - this.injured - this.poisoned - this.breeding - this.occupied;
    }

}
