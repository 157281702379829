import { EnemyBuilding } from "./EnemyBuilding";
import { EnemyBuildingUI } from "../ui/enemies/EnemyBuildingUI";
import { GameController } from "../GameController";

export class EnemyBase extends EnemyBuilding {
  type: string = "Enemy_Base";
  name: string = "Base";
  hp: number = 100;
  maxHp: number = 100;
  ui: EnemyBuildingUI | undefined;
  updateUI() {
    if (this.ui === undefined) return;
    this.ui.updateUI();
  }
  bindUi(ui: EnemyBuildingUI): void {
    this.ui = ui;
  }
  destroyed() {
    GameController.getController().endgame(true);
  }
}
