export abstract class Building {
  abstract type: string;
  abstract name: string;
  abstract hp: number;
  abstract maxHp: number;
  abstract parse: BuildingPhase;
  allocatedRabbits: number = 0;
  abstract nextRound(): void;
  abstract destroyed(): void;
  abstract getDamaged(damage: number): void;
  equals(building: Building): boolean {
    return building.type === this.type;
  }
}

export enum BuildingPhase {
  Building,
  Ready,
  Unavailable,
}
