import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { Component } from "react";
import { CarrotCannon } from "../../buildings/CarrotCannon";
import { BuildingPhase } from "../../buildings/Building";

export interface CarrotCannonUIProp {
  model: CarrotCannon;
}

export interface CarrotCannonState {
  parse: BuildingPhase;
  allocated: number;
  buildingRemaining: number;
  hp: number;
  target: string | undefined;
}

export class CarrotCannonUI extends Component<
  CarrotCannonUIProp,
  CarrotCannonState
> {
  static buttonString = new Map<BuildingPhase, string>([
    [BuildingPhase.Building, "Work"],
    [BuildingPhase.Ready, "Allocate"],
  ]);

  constructor(props: CarrotCannonUIProp) {
    super(props);
    this.model = props.model;
    this.model.bindUI(this);
    this.state = {
      parse: this.model.parse,
      allocated: this.model.allocatedRabbits,
      buildingRemaining: this.model.buildingCount,
      hp: this.model.hp,
      target: this.model.getTargetName(),
    };
  }

  updateUI() {
    this.setState({
      parse: this.model.parse,
      allocated: this.model.allocatedRabbits,
      buildingRemaining: this.model.buildingCount,
      hp: this.model.hp,
      target: this.model.getTargetName(),
    });
  }

  model: CarrotCannon;
  getDetails(): JSX.Element {
    if (this.model.parse === BuildingPhase.Building)
      return (
        <Stack flexWrap="wrap" gap="5px" direction="row">
          <Typography>{this.model.remainingConstruction}</Typography>
        </Stack>
      );
    return (
      <Stack flexWrap="wrap" gap="5px" direction="row">
        <Typography>{this.model.hp}</Typography>
        <Typography>{this.model.attack}</Typography>
      </Stack>
    );
  }
  render() {
    return (
      <Paper elevation={3}>
        <Stack direction="column" alignItems="flex-start" padding="10px">
          <Box alignSelf="stretch" color="gray">
            <LinearProgress
              variant="determinate"
              color={
                this.model.parse === BuildingPhase.Building
                  ? "inherit"
                  : "primary"
              }
              value={
                this.model.parse === BuildingPhase.Building
                  ? (1 -
                      this.state.buildingRemaining /
                        this.model.maxConstruction) *
                    100
                  : (this.state.hp / this.model.maxHp) * 100
              }
            />
          </Box>
          <Typography>{this.model.name}</Typography>
          {this.getDetails()}
          {this.state.parse === BuildingPhase.Building || (
            <Button
              onClick={this.model.startSelectTarget}
              variant="outlined"
              color={this.state.target === undefined ? "primary" : "error"}
            >
              {this.state.target === undefined ? "target" : this.state.target}
            </Button>
          )}
          <Button onClick={this.model.addRabbit}>
            {CarrotCannonUI.buttonString.get(this.state.parse)}{" "}
            {this.state.allocated}
          </Button>
        </Stack>
      </Paper>
    );
  }
}
