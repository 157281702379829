import { ValueUtils } from "../../utils/ValueUtils";
import { ResourcePointUI } from "../ui/components/ResourcePointUI";
import { HomeController } from "../HomeController";
import { GameController } from "../GameController";

export interface ResourceCollect {
  food: number;
  material: number;
  poison: number;
}

function addResourceCollect(
  ca: ResourceCollect,
  cb: ResourceCollect
): ResourceCollect {
  return {
    food: ca.food + cb.food,
    material: ca.material + cb.material,
    poison: ca.poison + cb.poison,
  };
}
export class ResourcePoint {
  type: "Food" | "Material";
  name: string;
  expectation: number;
  diversity: number;
  probHit: number;
  probPoison: number;
  forecastNum: number = 0;
  collectNum: number = 0;
  protectNum: number = 0;
  ambushNum: number = 0;
  static accuracyLevel(forecastNum: number): number {
    // todo: find a function that slowly shrink to 0
    if (forecastNum === 0) return 0.7;
    if (forecastNum === 1) return 0.5;
    if (forecastNum === 2) return 0.4;
    return 0.3;
  }
  ui: ResourcePointUI | undefined;
  bindUI(ui: ResourcePointUI) {
    this.ui = ui;
  }

  constructor(
    type: "Food" | "Material",
    name: string,
    expectation: number,
    diversity: number | undefined,
    probFood: number | undefined,
    probPoison: number | undefined
  ) {
    this.type = type;
    this.name = name;
    this.expectation = expectation;
    this.diversity = diversity ?? 0.7;
    this.probHit = probFood ?? 0.6;
    this.probPoison = probPoison ?? 0.1;
  }

  addForecast: () => void = () => {
    if (!HomeController.getInstance().allocateRabbit()) return;
    this.forecastNum += 1;
    const rangePercentage = ResourcePoint.accuracyLevel(this.forecastNum);
    const range = rangePercentage * this.expectation;
    this.expectation = ValueUtils.random(
      this.expectation - range,
      this.expectation + range
    );
    this.diversity = rangePercentage;
    this.ui?.updateUI();
  };

  addCollect: () => void = () => {
    if (!HomeController.getInstance().allocateRabbit()) return;
    this.collectNum += 1;
    this.ui?.updateUI();
  };

  addAmbush: () => void = () => {
    if (!HomeController.getInstance().allocateRabbit()) return;
    this.ambushNum += 1;
    this.ui?.updateUI();
  };

  addProtect: () => void = () => {
    if (!HomeController.getInstance().allocateRabbit()) return;
    this.protectNum += 1;
    this.ui?.updateUI();
  };

  collect(): ResourceCollect {
    const gaussianRandom: number = ValueUtils.gaussianRandom(
      this.expectation,
      this.diversity
    );
    let collected = Math.floor(gaussianRandom);
    if (Math.random() < gaussianRandom - collected) collected += 1;
    return {
      food: this.type === "Food" ? collected : 0,
      material: this.type === "Material" ? collected : 0,
      poison: 0,
    };
  }

  endTurn() {
    let collect: ResourceCollect = {
      food: 0,
      poison: 0,
      material: 0,
    };
    for (let i = 0; i < this.collectNum; i++) {
      collect = addResourceCollect(collect, this.collect());
    }
    const controller: HomeController = HomeController.getInstance();
    controller.changeFood(collect.food);
    controller.changeMaterial(collect.material);
    if (collect.food > 0)
      GameController.getController().addLog(
        `Get ${collect.food} food from ${this.name}`
      );
    if (collect.material > 0)
      GameController.getController().addLog(
        `Get ${collect.material} material from ${this.name}`
      );
  }
}
