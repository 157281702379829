import { AbstractCard } from "../../cards/AbstractCard";
import { Button, Card, Stack, Typography } from "@mui/material";

export function CardUI(props: { model: AbstractCard }): JSX.Element {
  function costBanner(model: AbstractCard): JSX.Element {
    if (model.foodCost <= 0 && model.materialCost <= 0)
      return <Typography>Free</Typography>;
    return (
      <Typography>
        {model.foodCost > 0 ? "Food: " + model.foodCost : ""}{" "}
        {model.materialCost > 0 ? "Material: " + model.materialCost : ""}
      </Typography>
    );
  }

  return (
    <Card elevation={3}>
      <Stack direction="column" padding="10px" alignItems="start">
        <Typography color="gray">{props.model.cardType}</Typography>
        <Typography variant="h6">{props.model.cardName}</Typography>
        {costBanner(props.model)}
        {props.model.cardInfo.map((card, index) => (
          <Typography key={index}>{card}</Typography>
        ))}
        <Button fullWidth={true} onClick={() => props.model.operateCard()}>
          Use
        </Button>
      </Stack>
    </Card>
  );
}
