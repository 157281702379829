export class ValueUtils {
    static random(min: number, max: number): number {
        return min + (max - min) * Math.random();
    }

    static gaussianRandom(mean=0, stdev=1) {
        let u = 1 - Math.random(); //Converting [0,1) to (0,1)
        let v = Math.random();
        let z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
        return z * stdev + mean;
    }
}
