import { AbstractCard, CardType } from "./AbstractCard";
import { GameController } from "../GameController";
import { HomeController } from "../HomeController";
import { CarrotCannon } from "../buildings/CarrotCannon";

export class CarrotCannonCard extends AbstractCard {
  cardName: string = "Carrot Cannon";
  cardType: CardType = CardType.Building;
  foodCost: number = 0;
  materialCost: number = 10;
  cardInfo: string[] = ["attack: 10", "hp: 20"];
  operateCard() {
    if (GameController.getController().numUnderConstruction >= 3) return;
    if (this.foodCost > HomeController.getInstance().food) return;
    HomeController.getInstance().changeFood(-this.foodCost);
    if (this.materialCost > HomeController.getInstance().material) return;
    HomeController.getInstance().changeMaterial(-this.materialCost);
    GameController.getController().numUnderConstruction += 1;
    HomeController.getInstance().addBuilding(new CarrotCannon());
  }
}
