import { Base } from "./home/Base";
import { Rabbits } from "./home/Rabbits";
import { Building } from "./buildings/Building";
import { StackInfoUIPart } from "./ui/parts/StackInfoUIPart";
import { BaseUIPart } from "./ui/parts/BaseUIPart";
import { BuildingsUIPart } from "./ui/parts/BuildingsUIPart";
import { GameController } from "./GameController";

export class HomeController {
  private static instance: HomeController | null = null;
  static getInstance(): HomeController {
    if (HomeController.instance == null)
      HomeController.instance = new HomeController();
    return HomeController.instance;
  }

  base: Base;
  rabbits: Rabbits;
  buildings: Building[];
  food: number;
  material: number;
  stackInfoUI: StackInfoUIPart | undefined;
  baseUI: BaseUIPart | undefined;
  buildingsUI: BuildingsUIPart | undefined;
  updateStackUI(): void {
    if (this.stackInfoUI === undefined) return;
    this.stackInfoUI.updateUI();
  }
  updateBaseUI(): void {
    if (this.baseUI === undefined) return;
    this.baseUI.updateUI();
  }
  updateBuildingsUI(): void {
    if (this.buildingsUI === undefined) return;
    this.buildingsUI.updateUI();
  }

  constructor() {
    this.base = new Base();
    this.rabbits = new Rabbits();
    this.buildings = [];
    this.food = 0;
    this.material = 0;
  }

  calFoodConsumption(units: number): number {
    if (units <= 10) return units - 1;
    if (units <= 20) return 1.5 * units - 5;
    if (units <= 30) return 2 * units - 10;
    return 2.2 * units - 12;
  }

  nextRound(): void {
    const foodConsumption: number = this.calFoodConsumption(this.rabbits.total);
    if (this.food < foodConsumption) {
      const diff: number = foodConsumption - this.food;
      this.rabbits.total -= diff;
      this.food = 0;
    } else {
      this.food -= foodConsumption;
    }
    const breedingNum: number = Math.floor(this.rabbits.breeding / 2);
    this.rabbits.total += breedingNum;
    GameController.getController().addLog(
      `Breed ${breedingNum} rabbits at home`
    );
    this.rabbits.occupied = 0;
    this.rabbits.breeding = 0;
    this.updateBaseUI();
    this.updateStackUI();
  }

  addBuilding(building: Building) {
    this.buildings.push(building);
    this.updateBuildingsUI();
  }

  allocateRabbit: () => boolean = () => {
    if (this.rabbits.getAvailable() <= 0) return false;
    this.rabbits.occupied += 1;
    this.updateStackUI();
    return true;
  };

  addBreeding: () => void = () => {
    if (this.rabbits.getAvailable() <= 0) return false;
    this.rabbits.breeding += 1;
    this.updateBaseUI();
    this.updateStackUI();
  };

  changeFood(foodChange: number): void {
    this.food += foodChange;
    this.updateStackUI();
  }

  changeMaterial(materialChange: number): void {
    this.material += materialChange;
    this.updateStackUI();
  }
}
