import { ResourcePoint } from "../../resources/ResourcePoint";
import { Component } from "react";
import { GameController } from "../../GameController";
import { ResourcePointUI } from "../components/ResourcePointUI";
import { Stack } from "@mui/material";
import { ResourceController } from "../../ResourceController";

interface ResourcesUIPartProps {}
interface ResourcesUIPartState {
  resources: ResourcePoint[];
}

export class ResourcesUIPart extends Component<
  ResourcesUIPartProps,
  ResourcesUIPartState
> {
  controller: ResourceController;
  constructor(props: ResourcesUIPartProps) {
    super(props);
    this.controller = ResourceController.getController();
    this.controller.ui = this;
    this.state = {
      resources: ResourceController.getController().resources,
    };
  }

  updateUI() {
    this.setState({ resources: ResourceController.getController().resources });
  }
  render(): JSX.Element {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="20px"
      >
        {this.state.resources.map((resource, index) => (
          <ResourcePointUI
            model={resource}
            key={
              GameController.getController().turn.toString() +
              "-" +
              index.toString()
            }
          />
        ))}
      </Stack>
    );
  }
}
