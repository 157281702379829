import { EnemyBuilding } from "../../enemies/EnemyBuilding";
import { Stack } from "@mui/material";
import { EnemyBuildingUI } from "../enemies/EnemyBuildingUI";
import { EnemyController } from "../../EnemyController";
import { useEffect, useState } from "react";

export function EnemyUIPart(): JSX.Element {
  const [buildings, setBuildings] = useState<EnemyBuilding[]>(
    EnemyController.getController().buildings
  );
  useEffect(
    () =>
      (EnemyController.getController().updateEnemyUI = () =>
        setBuildings(EnemyController.getController().buildings)),
    []
  );
  return (
    <Stack direction="row" gap="10px" justifyContent="center">
      <EnemyBuildingUI
        model={EnemyController.getController().base}
        key="enemy-base"
      />
      {buildings.map((building, index) => (
        <EnemyBuildingUI model={building} key={index} />
      ))}
    </Stack>
  );
}
