import { Component } from "react";
import {
  Box,
  Dialog,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { HomeController } from "../../HomeController";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface StackInfoUIPartProps {}
interface StackInfoUIPartState {
  availableRabbit: number;
  food: number;
  material: number;
  foodTipOpen: boolean;
}

const foodIntroduction: { col1: string; col2: string }[] = [
  { col1: "1~10", col2: "#Rabbits" },
  { col1: "11~20", col2: "1.5 x #Rabbits - 5" },
  { col1: "21~30", col2: "2 x #Rabbits - 10" },
  { col1: "31+", col2: "2.2 x #Rabbits - 12" },
];

export class StackInfoUIPart extends Component<
  StackInfoUIPartProps,
  StackInfoUIPartState
> {
  controller: HomeController;
  constructor(props: StackInfoUIPartProps) {
    super(props);
    this.controller = HomeController.getInstance();
    this.controller.stackInfoUI = this;
    this.state = {
      availableRabbit: this.controller.rabbits.getAvailable(),
      food: this.controller.food,
      material: this.controller.material,
      foodTipOpen: false,
    };
  }

  updateUI(): void {
    this.setState({
      availableRabbit: this.controller.rabbits.getAvailable(),
      food: this.controller.food,
      material: this.controller.material,
    });
  }

  render(): JSX.Element {
    return (
      <Box>
        <Stack direction="column" alignItems="flex-start">
          <Typography>
            Available Rabbit: {this.state.availableRabbit}
          </Typography>
          <Typography>
            Food Storage: {this.state.food}{" "}
            <InfoOutlinedIcon
              onClick={() => this.setState({ foodTipOpen: true })}
            />
          </Typography>
          <Typography>Material: {this.state.material}</Typography>
        </Stack>
        <Dialog
          open={this.state.foodTipOpen}
          onClose={() => this.setState({ foodTipOpen: false })}
        >
          <TableContainer component={Paper}>
            <Table aria-label="food-intro">
              <TableHead>
                <TableRow>
                  <TableCell>#Rabbits</TableCell>
                  <TableCell>Consumption</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foodIntroduction.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.col1}</TableCell>
                    <TableCell>{row.col2}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Dialog>
      </Box>
    );
  }
}
