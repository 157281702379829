import { Component } from "react";
import { Button, Paper, Stack, Typography } from "@mui/material";
import { HomeController } from "../../HomeController";
import { Building } from "../../buildings/Building";

interface BaseUIPartProps {}
interface BaseUIPartState {
  breedingNum: number;
  buildings: Building[];
}

export class BaseUIPart extends Component<BaseUIPartProps, BaseUIPartState> {
  controller: HomeController;
  constructor(props: BaseUIPartProps) {
    super(props);
    this.controller = HomeController.getInstance();
    this.controller.baseUI = this;
    this.state = {
      breedingNum: this.controller.rabbits.breeding,
      buildings: this.controller.buildings,
    };
  }

  updateUI(): void {
    this.setState({
      breedingNum: this.controller.rabbits.breeding,
    });
  }

  updateUIBuildingAddRemove(): void {
    this.setState({ buildings: this.controller.buildings });
  }

  render(): JSX.Element {
    return (
      <Paper elevation={3}>
        <Stack
          direction="column"
          padding="10px"
          alignItems="flex-start"
          height="200px"
        >
          <Typography color="gray">Base</Typography>
          <Stack direction="row" flexWrap="wrap" gap="5px">
            <Button variant="outlined" onClick={this.controller.addBreeding}>
              Breed {this.state.breedingNum}
            </Button>
          </Stack>
        </Stack>
      </Paper>
    );
  }
}
