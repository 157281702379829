import { Box, Button, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { GameController } from "../../GameController";
import MenuBookIcon from "@mui/icons-material/MenuBook";

export function LogUIPart(): JSX.Element {
  const [round, setRound] = useState<number>(
    GameController.getController().turn
  );
  const [logs, setLogs] = useState<string[]>(
    GameController.getController().gameLog
  );

  useEffect(() => {
    GameController.getController().updateLogUI = () => {
      setRound(GameController.getController().turn);
      setLogs(GameController.getController().gameLog);
    };
  }, []);

  return (
    <Stack
      direction="column"
      alignItems="flex-start"
      sx={{ maxHeight: "calc(100vh - 245px)" }}
    >
      <Stack direction="row">
        <Button
          startIcon={<MenuBookIcon />}
          onClick={() => window.open("/intro", "_blank")}
        >
          Intro
        </Button>
      </Stack>
      <Typography variant="h5">Round {round}</Typography>
      <Box flexGrow={1} sx={{ overflowY: "auto" }} alignSelf="stretch">
        {logs.map((log, index) => (
          <Typography key={index}>{log}</Typography>
        ))}
      </Box>
    </Stack>
  );
}
